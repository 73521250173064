.toggle-button {
    display: flex;
    justify-content: center;
    cursor: default;
  }
  
  .side {
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    width: 100px;
  }
  
  .side.selected {
    background-color: blue;
    color: white;
  }

  .type {
    padding: 5px;
    width: 40px;
  }