.results-list {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 20px;
}

.results-div {
    color: blue;
}