.game-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 4px solid rgb(113, 241, 113);
    margin-bottom: 10px;
    width: 300px;
  }

  .game-container.locked {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 4px solid red;
    margin-bottom: 10px;
    width: 300px;
  }
  
  .game-button {
    display: flex;
    flex-direction: column; /* Change flex direction to column */
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    border: none;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  
  .team-info {
    display: flex;
    align-items: center;
  }
  
  .team-details {
    display: flex;
    flex-direction: column; /* Change flex direction to column */
    align-items: center;
    margin: 0 10px;
  }
  
  .team-details h3 {
    margin: 0;
    margin-top: 5px; /* Add margin to the top of team names */
  }
  
  .team-details img {
    width: 50px;
    height: 50px;
  }

  
  .game-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-items: center;
  }

  .toggle-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.toggle-button {
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
}

.toggle-button.active {
  background-color: #007bff;
  color: white;
}

.row-one {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.row-two {
    display: flex;
    gap: 20px;
    justify-content: center;
}
/* Rest of your CSS styles... */
