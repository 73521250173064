.profile-game-container {
    align-items: center;
    padding: 10px;
    border: 4px solid rgb(0, 0, 0);
    margin-bottom: 10px;
    width: 50%;
    /* justify-content: center; */
}

.profile-game-button {
    display: flex;
    flex-direction: column; /* Change flex direction to column */
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    border: none;
    padding: 10px;
    margin-top: 10px;
    width: 90%;
    text-align: center;
  }

  .slides {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  .slide-button {
    background-color: rgb(193, 205, 198);
    width: 60px;
    color: rgb(0, 0, 0);
    text-align: center;
    cursor: pointer;
  }

  .slide-button:hover {
    background-color: rgb(184, 184, 184);
    width: 60px;
    color: white;
    text-align: center;
    cursor: pointer;
  }

  .selected-week {
    background-color: #333aff; /* Change to your desired highlight color */
    color: white;
    /* Add any other styles you want for the selected week button */
  }