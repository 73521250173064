.gs {
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: default;
    width: 100px;
  }
  
  .gs.selected {
    background-color: blue;
    color: white;
    cursor: default;
  }

  .favorite-team-img {
    width: 50px;
    height: 50px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 5px solid red; */
  }

