/* Leaderboard.css */

.leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .leaderboard-card {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 16px;
    margin: 20px 10px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .user-info {
    display: flex;
    align-items: center;
  }
  
  .username {
    font-size: 30px;
    margin-right: 10px;
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease-in-out;
  }
  
  .username:hover {
    color: #f39c12;
  }
  
  .leaderboard-favorite-team-img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
  
  .score {
    font-size: 16px;
    margin-top: 8px;
  }
  